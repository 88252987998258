$(document).ready(function () {
    /** @global SCHEDULE **/
    /** @global SELECTED_LOCATION_ID **/
    /** @global DAY_FROM_NUMBER **/
    /** @global DAY_TO_NUMBER **/
    /** @global YOUNG_DRIVER_PRICE **/

    //карусель на главной
    if ($(".owl-carousel").length) {
        var owl = $(".owl-carousel");
        owl.owlCarousel({
            center: true,
            margin: 140,
            loop: true,
            items: 1,
            dots: true,
            nav: true,
            dotsContainer: ".dots-container",
            responsive: {
                1200: {
                    items: 2,
                }
            }
        }).on('translated.owl.carousel', function (event) {
            let vanType = $('.owl-carousel').find('.item').eq(event.item.index).data('type')
            Cookies.set('booking_van_type', vanType)
            $('#van-type').val(vanType);
        });

        $('.owl-nav').on('click', function (e) {
            if (!$(e.target).is('button') && !$(e.target).is('span')) {
                e.preventDefault();
                $(this).closest('section').find('form').submit();
            }
        });
    }

    //инициализация фильтра для каталога
    if ($(".catalog").length) {
        initMixer();
        filterItems();
    }

    //подгрузка каталога
    $(".more-cars").on("click", function (e) {
        e.preventDefault();
        $.ajax({
            method: "post",
            url: "/partials/catalog-item.php",
            success: function (resp) {
                document.mixer.append(resp);
                if ($('body').find('.catalog-item').length >= 6) {
                    $('.more-cars').hide();
                }
            }
        });
    });

    //применение фильтров при выборе чекбоксов
    $(".catalog-filters button").on("click", function (e) {
        e.preventDefault();
        $(".catalog-filters button").removeClass('active');
        $(this).addClass('active');
        filterItems();
    });

    //выбор времени
    if ($('.time-input').length) {
        $('.time-input').timepicker({
            timeFormat: 'HH:mm',
            interval: 60,
            minTime: '8:00',
            maxTime: '23:00',
            scrollDefault: '10:00',
            startTime: '08:00',
            dynamic: false,
            dropdown: true,
            scrollbar: false,
        });
    }

    if ($('.order-form').length) {
        // маска ввода телефона
        IMask(
            document.getElementById('phone-input'),
            {
                mask: [
                    {
                        mask: '+00 000 000 000 00',
                        startsWith: '+',
                        lazy: false,
                        placeholderChar: ' ',
                        blocks: {
                            country: {
                                mask: '00'
                            },
                            part1: {
                                mask: '000'
                            },
                            part2: {
                                mask: '0000'
                            },
                            part3: {
                                mask: '0000'
                            }
                        }
                    }
                ]
            });

        // маска ввода дня рождения
        IMask(
            document.getElementById('date-of-birth-input'),
            {
                mask: Date,
                min: new Date(1900, 0, 1),
                max: new Date(),
                lazy: false
            }
        )

        document.getElementById('date-of-birth-input').addEventListener('blur', validateAge);
    }

    var modalPhone = document.getElementById('modal-phone');
    var modalPhoneOptions = {
        mask: '+00 000 000 000',
    };
    IMask(modalPhone, modalPhoneOptions);

    if($('.cookies-warning').length) {
        $('body').on('click', '.cookies-warning .close, .cookies-warning .red-button', function () {
            document.cookie = "cookies_warning=checked; samesite=strict; max-age=" + 3600 * 24 * 90;
            $('.cookies-warning').hide(200)
        })
    }

    //todo проверить, используется ли
    $('#callback-form').on('submit', function (e) {
        e.preventDefault();
        var form = $(this);
        $.ajax({
            type: 'POST',
            data: form.serialize(),
            success: function (resp) {
                var html = '<p class="text-left">¡Gracias!</p>';
                var phone = form.data('phone');
                var clearPhone = phone.replace(/\s/g, '');
                html += '<p class="text-left">Le llamaremos en 15 minutos en horario laboral. Por si acaso, nuestro teléfono es <a href="tel:' +
                    clearPhone + '">' + phone + '</a>.</p>';
                form.html(html);
            }
        });
    });

    //модальное меню
    $('.navbar-toggler').on('click', function (e) {
        e.preventDefault();
        $('.mobile-menu').css("display", "flex").hide().fadeIn();
    });

    $('.mobile-close').on('click', function (e) {
        e.preventDefault();
        $('.mobile-menu').fadeOut();
    });

    //инициализация класса фильтрации
    function initMixer() {
        document.mixer = mixitup(".catalog .container", {
            selectors: {
                target: ".catalog-item-wrapper"
            },
            load: {
                sort: 'default-order:asc'
            },
        });
    }

    //фильтрация в каталоге
    function filterItems() {
        document.mixer.sort($('.filter.active').data('size') + ':desc');
    }

    // двухшаговая модалка выбора локации и времени
    const body = $('body')

    function createMapElement(url) {
        let preparedMap = document.createElement('iframe')
        preparedMap.src = url
        preparedMap.async = true
        preparedMap.style.cssText += 'border: 0; width: 100%; height: 150px; max-height: 100%;'
        preparedMap.allowfullscreen = ''
        preparedMap.loading = "lazy"

        return preparedMap
    }

    function setMapInCurrentLocation() {
        let newMapUrl = $('.remodal .step-one .locations .location.current').data('map')
        $('.remodal .step-one .locations .location.current .map').html(createMapElement(newMapUrl))
    }

    setMapInCurrentLocation()

    body.on('click', '.remodal .step-one .next-step', function () {
        let location = jQuery('.remodal .step-one .locations .title.current').data('name')

        updateDatepicker()
        updateTimepickerSlider(timeFromSlider, true)
        updateTimepickerSlider(timeToSlider, false)

        Cookies.set('location', location)

        $('.remodal .step-one').addClass('d-none')
        $('.remodal .step-two').removeClass('d-none')

        jQuery('.remodal .step-two .next-step').attr('disabled', true)
    })

    function postRedirect(url, data = null) {
        window.location.href = url
    }

    body.on('click', '.open-modal', function () {
        $('.remodal .step-one').removeClass('d-none')
        $('.remodal .step-two').addClass('d-none')

        if (jQuery(this).hasClass('forget-van-type')) {
            Cookies.remove('booking_van_type')
        } else {
            let vanType = jQuery('.van-types .owl-carousel').find('.owl-item.active.center:first .item').data('type')
            Cookies.set('booking_van_type', vanType)
        }
    })

    body.on('change', '#datarange_inside_remodal', function () {
        let dateRange = jQuery('#datarange_inside_remodal').val()
        let splitted = dateRange.split('--')

        if (2 !== splitted.length) {
            jQuery('.remodal .step-two .next-step').attr('disabled', true)
        } else {
            jQuery('.remodal .step-two .next-step').attr('disabled', false)
        }
    })

    body.on('click', '.remodal .step-two .next-step', function () {
        let dateRange = jQuery('#datarange_inside_remodal').val()
        let splitted = dateRange.split('--')

        if (2 !== splitted.length) {
            jQuery('.remodal .step-two .next-step').attr('disabled', true)
        } else {
            jQuery('.remodal .step-two .next-step').attr('disabled', false)

            let timeFrom = jQuery('#time-from').val()
            let timeTo = jQuery('#time-to').val()

            const params = new URLSearchParams({
                date_range: dateRange,
                time_from: timeFrom,
                time_to: timeTo,
                location: Cookies.get('location')
            });

            postRedirect('/catalog?' + params.toString())
        }
    })

    jQuery('body').on('change', '#time-from', function () {
        SELECTED_TIME_FROM = jQuery(this).val()
        console.log('Сохранили значение #time-from: ' + SELECTED_TIME_FROM)
    })

    jQuery('body').on('change', '#time-to', function () {
        SELECTED_TIME_TO = jQuery(this).val()
        console.log('Сохранили значение #time-to: ' + SELECTED_TIME_TO)
    })

    function getActiveLocationId() {
        return jQuery('.remodal .step-one .locations .title.current').data('id');
    }

    function getDisabledDays(locationId) {
        const disabled = [];

        for (var key of Object.keys(SCHEDULE[locationId])) {
            if (false == SCHEDULE[locationId][key]) {
                disabled.push(key)
            }
        }

        return disabled;
    }

    /**
     * @see https://flatpickr.js.org/examples/#mindate-and-maxdate
     */
    function initDatepicker() {
        const today = new Date(new Date().getTime()).toLocaleDateString('en-US')
        const Spanish = {
            weekdays: {
                shorthand: ["Dom", "Lun", "Mar", "Mié", "Jue", "Vie", "Sáb"],
                longhand: [
                    "Domingo",
                    "Lunes",
                    "Martes",
                    "Miércoles",
                    "Jueves",
                    "Viernes",
                    "Sábado",
                ],
            },

            months: {
                shorthand: [
                    "Ene",
                    "Feb",
                    "Mar",
                    "Abr",
                    "May",
                    "Jun",
                    "Jul",
                    "Ago",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dic",
                ],
                longhand: [
                    "Enero",
                    "Febrero",
                    "Marzo",
                    "Abril",
                    "Mayo",
                    "Junio",
                    "Julio",
                    "Agosto",
                    "Septiembre",
                    "Octubre",
                    "Noviembre",
                    "Diciembre",
                ],
            },

            ordinal: () => {
                return "º";
            },

            firstDayOfWeek: 1,
            rangeSeparator: "--",
            time_24hr: true,
        };

        window.datepicker = flatpickr('#datarange_inside_remodal', {
            locale: Spanish,
            inline: true,
            mode: "range",
            disableMobile: true,
            weekNumbers: false,
            dateFormat: "m/d/Y",
            minDate: today,
            onReady: function () {
                $('input.numInput.cur-year').attr('readonly', 'readonly')
            },
            onDayCreate: function (dObj, dStr, fp, dayElem) {
                if(false === SCHEDULE[SELECTED_LOCATION_ID][dayElem.dateObj.getDay()]) {
                    jQuery(dayElem).addClass('flatpickr-disabled')
                }
                else if (0 === dayElem.dateObj.getDay()) {
                    jQuery(dayElem).addClass('red')
                }
            },
            onChange: function (selectedDates, dateStr, instance) {
                const today = new Date(new Date().getTime()).toLocaleDateString('en-US')

                // выбрана только стартовая дата
                instance.set('disable', [
                    function (date) {
                        return date < today
                    }
                ]);

                if (1 === selectedDates.length) {
                    // выключаем только даты раньше выбранной
                    instance.set('disable', [
                        function (date) {
                            return date < selectedDates[0]
                        }
                    ]);
                    DAY_FROM_NUMBER = selectedDates[0].getDay()
                    updateTimepickerSlider(timeFromSlider, true)
                } else if (2 === selectedDates.length) {
                    DAY_FROM_NUMBER = selectedDates[0].getDay()
                    DAY_TO_NUMBER = selectedDates[1].getDay()

                    updateTimepickerSlider(timeFromSlider, true)
                    updateTimepickerSlider(timeToSlider, false)
                } else {
                    // сбрасываем блокировки
                    instance.set('disable', [
                        function (date) {
                            return false
                        }
                    ]);
                }
            },
        })
    }

    function updateDatepicker() {
        const locationId = getActiveLocationId();
        const disabledDays = getDisabledDays(locationId);
        const flatpickr = document.querySelector("#datarange_inside_remodal")

        // выключаем недоступные дни
        flatpickr._flatpickr.set('disable', [
            function (date) {
                return (-1 !== disabledDays.indexOf(parseInt(date.getDay())));
            }
        ])

        // раскрашиваем их красным
        flatpickr._flatpickr.days.childNodes.forEach(function (el) {
            if (-1 !== disabledDays.indexOf(el.dateObj.getDay())) {
                jQuery(el).addClass('red')
            } else {
                jQuery(el).removeClass('red')
            }
        })
    }

    function prepareTimepickerValues(from, to) {
        const times = [];

        let counter = 1;
        while (from != to) {
            times.push(from)

            let splitted = from.split(':')
            splitted[0] = parseInt(splitted[0]) // число всегда целое, без нуля впереди

            if (splitted[1] == '30') {
                from = parseInt(splitted[0] + 1) + ':00'
            } else {
                from = splitted[0] + ':30'
            }
        }

        times.push(from) // последний элемент не попал в итерацию, добавим его вручную

        return times;
    }

    /**
     * @see http://ionden.com/a/plugins/ion.rangeSlider/index.html
     * @param selector
     */
    function initTimepickerSlider(from = true) {
        const selector = from ? '#time-from' : '#time-to'
        const dayNumber = from ? DAY_FROM_NUMBER : DAY_TO_NUMBER
        const defaultKey = from ? 'from_default' : 'to_default'
        const daySchedule = SCHEDULE[SELECTED_LOCATION_ID][dayNumber]
        const defaultValue = daySchedule[defaultKey]
        const values = from
            ? prepareTimepickerValues(daySchedule.from_start, daySchedule.from_end)
            : prepareTimepickerValues(daySchedule.to_start, daySchedule.to_end)

        if (null !== defaultValue && -1 !== values.indexOf(defaultValue)) {
            let index = values.indexOf(defaultValue)

            jQuery(selector).ionRangeSlider({
                skin: "round",
                values: values,
                from: index // используется индекс вместо конкретного значения
            });
        } else {
            jQuery(selector).ionRangeSlider({
                skin: "round",
                values: values
            });
        }

        let wrapper = jQuery(selector).closest('.timepicker-wrapper')
        wrapper.find('.before').html(values[0])
        wrapper.find('.after').html(values[values.length - 1])

        return jQuery(selector).data("ionRangeSlider")
    }

    function updateTimepickerSlider(instance, from = false) {
        const dayNumber = from ? DAY_FROM_NUMBER : DAY_TO_NUMBER
        const defaultKey = from ? 'from_default' : 'to_default'
        const oldDefaultValue = from ? SELECTED_TIME_FROM : SELECTED_TIME_TO
        const daySchedule = SCHEDULE[SELECTED_LOCATION_ID][dayNumber]
        const defaultValue = daySchedule[defaultKey]
        const values = from
            ? prepareTimepickerValues(daySchedule.from_start, daySchedule.from_end)
            : prepareTimepickerValues(daySchedule.to_start, daySchedule.to_end)

        let index;
        if (null !== oldDefaultValue && -1 !== values.indexOf(oldDefaultValue)) {
            index = values.indexOf(oldDefaultValue)
            console.log('старое значение ' + oldDefaultValue + ' существует')
        } else {
            index = values.indexOf(defaultValue)
            console.log('старое значение ' + oldDefaultValue + ' не существует, ставим дефолтное ' + defaultValue + '; массив значений: ', daySchedule)

            if (from) {
                SELECTED_TIME_FROM = defaultValue
            } else {
                SELECTED_TIME_TO = defaultValue
            }
        }

        instance.update({
            values: values,
            from: index // используется индекс вместо конкретного значения
        })

        const selector = jQuery(instance.input)
        const wrapper = jQuery(selector).closest('.timepicker-wrapper')

        // проставляем начальную и конечную точку
        wrapper.find('.before').html(values[0])
        wrapper.find('.after').html(values[values.length - 1])
    }

    initDatepicker()

    let timeFromSlider = initTimepickerSlider(true)
    let timeToSlider = initTimepickerSlider(false)

    body.on('click', '.remodal .step-one .locations .titles .title', function () {
        if ($(this).hasClass('current')) return

        $(this).addClass('current').siblings().removeClass('current')
        $('.remodal .step-one .locations .location').removeClass('current').eq($(this).index()).addClass('current')

        SELECTED_LOCATION_ID = $(this).data('id')
        setMapInCurrentLocation()

        // меняем часы работы
        updateTimepickerSlider(timeFromSlider, true)
        updateTimepickerSlider(timeToSlider, false)
    })

    // проверка доступности автомобилей
    function getUrlParams() {
        var params = {};
        var queryString = window.location.search.substring(1);
        var vars = queryString.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair.length === 2) {
                params[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
            }
        }
        return params;
    }

    let data = getUrlParams();
    data.rendered_cars = [];
    $('.api-catalog-results .catalog-item-wrapper').each(function () {
        data.rendered_cars.push( $(this).data('group') )
    })

    if($('section.catalog').length) {
        jQuery.ajax({
            method: "post",
            data,
            url: "/available-in-another-location-ajax",
            dataType: 'json',
            success: function (response) {
                $('body').find('.api-catalog-results').append(response.html)
            }
        });
    }

    // показ блока "Автомобили не найдены"
    if (jQuery('.api-catalog-results .col-12').length < 1) {
        jQuery('.empty-results').removeClass('d-none')
    }

    $('form input[type=text], form input[type=email]').on('input', function () {
        $(this).clearValidationError();
    });

    $('form input[type=checkbox]').on('change', function () {
        $(this).clearValidationError();
    });

    $.fn.showValidationError = function (message) {
        $(this)
            .tooltipster({
                animation: 'fade',
                trigger: 'click',
                contentCloning: true,
                contentAsHTML: true,
                delay: 200
            })
            .tooltipster('content', message)
            .tooltipster('open');
    }

    $.fn.clearValidationErrors = function () {
        validationErrors.forEach((item) => {
            $('#' + item.id).clearValidationError()
        })
    }

    $.fn.clearValidationError = function () {
        // проверим, что instance tooltipster`а для этого элемента существует
        let instances = $.tooltipster.instances()
        let name = $(this).attr('id')
        instances.forEach(function (instance) {
            if (instance._$origin.attr('id') === name) {
                $('#' + name).tooltipster('instance').destroy()
            }
        })

        let index = validationErrors.find(i => i.id === name)
        validationErrors.splice(index, 1)
    }

    const validationErrors = []

    function validateAge() {
        const dateStr = document.getElementById('date-of-birth-input').value;

        const datePattern = /^\d{2}\.\d{2}\.\d{4}$/;
        if (!datePattern.test(dateStr)) {
            validationErrors.push({
                id: 'date-of-birth-input',
                message: "La fecha de nacimiento debe estar en formato DD.MM.YYYY."
            })
            return;
        }

        const [day, month, year] = dateStr.split('.').map(Number);
        const date = new Date(year, month - 1, day);

        const today = new Date();
        let age = today.getFullYear() - date.getFullYear();
        const monthDiff = today.getMonth() - date.getMonth();
        if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < date.getDate())) {
            age--;
        }

        if (age < 21) {
            validationErrors.push({
                id: 'date-of-birth-input',
                message: 'Debe tener al menos 21 años.'
            });
            return;
        }

        $youngDriverContainer = $('.joung-driver');
        if(age < 26) {
            $youngDriverContainer.find('#joungDriverPrice').text(YOUNG_DRIVER_PRICE.toString() + '€');
            $youngDriverContainer.removeClass('d-none')
        } else {
            $youngDriverContainer = $('.joung-driver').addClass('d-none');
        }
    }

    $.fn.validateForm = function () {
        $(this).clearValidationErrors()

        if ($(this).find('input[type=checkbox]').prop('checked') != true) {
            validationErrors.push({
                id: $(this).find('input[type=checkbox]').attr('id'),
                message: 'Doy el consentimiento del tratamiento de los datos personales.'
            })
        }

        if ($(this).find('#first-name-input').val() == '') {
            validationErrors.push({
                id: 'first-name-input',
                message: 'Su nombre es obligatorio'
            })
        }

        if ($(this).find('#last-name-input').val() == '') {
            validationErrors.push({
                id: 'last-name-input',
                message: 'Por favor, introduzca su apellido'
            })
        }

        let email = $(this).find('input[type=email]').val()
        var emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (!email.match(emailPattern)) {
            validationErrors.push({
                id: $(this).find('input[type=email]').attr('id'),
                message: 'Sú correo electrónico es obligatorio'
            })
        }

        var clearPhone = $(this).find('.phone-input').val().replace(/\s/g, '');

        if (clearPhone.length < 5) {
            validationErrors.push({
                id: $(this).find('.phone-input').attr('id'),
                message: 'Su número de teléfono es obligatorio'
            })
        }

        validateAge();

        if (validationErrors.length) {
            validationErrors.forEach((item) => {
                $('#' + item.id).showValidationError(item.message)
            })

            return false;
        }

        return true;
    }

    // Оплата
    $.fn.checkFormBeforeSubmit = function (e) {
        e.preventDefault();
        return $(this).closest('form').validateForm()
    }

    // Бронирование ajax
    body.on('click', 'section.order-page #form-submit', function (e) {
        if (!$(this).checkFormBeforeSubmit(e)) {
            return
        }

        jQuery.ajax({
            method: "post",
            data: jQuery(this).closest('form').serialize(),
            url: "/order-create",
            dataType: 'json',
            success: function (response) {
                postRedirect(response.url)
            }
        });
    })

    body.on('click', 'section.order-page #form-submit-payment', function (e) {
        if (!$(this).checkFormBeforeSubmit(e)) {
            return;
        }

        const $button = $(this)
        if ($button.hasClass('processing')) {
            return;
        }

        $button.addClass('processing')
        $button.closest('div').find('.loader').removeClass('d-none')

        jQuery.ajax({
            method: "post",
            data: jQuery(this).closest('form').serialize(),
            url: "/order-create?is_payment=1",
            dataType: 'json',
            success: function (response) {
                if (response.Ds_MerchantParameters) {
                    const form = '<form id="pay-form" style="visibility:hidden; position:absolute; top: 0" action="' + response.url + '" method="POST">' +
                        '<input type="hidden" name="Ds_MerchantParameters" value="' + response.Ds_MerchantParameters + '">' +
                        '<input type="hidden" name="Ds_Signature" value="' + response.Ds_Signature + '">' +
                        '<input type="hidden" name="Ds_SignatureVersion" value="' + response.Ds_SignatureVersion + '">' +
                        '<input type="submit">' +
                        '</form>'

                    jQuery('body').append(form);
                    jQuery('#pay-form').submit();
                }
            },
            always: function () {
                $button.removeClass('processing')
                $button.closest('div').find('.loader').addClass('d-none')
            }
        });
    })

    // Swiper slider
    if ($('.car-images-slider').length) {
        const imagesSlider = new Swiper('.car-images-slider', {
            loop: false,
            slidesPerView: 1,
            spaceBetween: 30,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
    }

    // 31 day +
    if ($('.too-long-interval').length) {
        $('.catalog-filters').hide();

        // YM event
        setTimeout(function () {
            try {
                console.log('reached 31 day ym target')
                ym(70612081, 'reachGoal', '31dia')
            } catch (e) {
            }
        }, 1000)
    }

    // Автоматическое открытие формы подбора на главной странице при редиректе с solorentacar.com
    const autoOpenSearchAutoForm = function () {
        const url = new URL(window.location.href);
        const paramName = 'openForm';

        if (
            url.searchParams.has(paramName)
            && url.searchParams.get(paramName) === 'true'
            && $('.button-to-time-overlay').length
        ) {
            body.find('.button-to-time-overlay').trigger('click');
        }
    }

    autoOpenSearchAutoForm();
})
